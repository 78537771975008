export const socialLinks = [

	{
		id: 1,
		name: 'YouTube',
		icon: 'youtube',
		url: 'https://www.youtube.com/@deptart_pl',
	},
	{
		id: 2,
		name: 'Instagram',
		icon: 'instagram',
		url: 'https://www.instagram.com/deptart.pl/',
	},
	{
		id: 3,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/deptadominik',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://www.linkedin.com/in/dominik-depta01/',
	},
];
